import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      sidebar: {
        home: "Home",
        vehicles: "Vehicles",
        drivers: "Teams",
        services: "Services",
        pricing: "Pricing",
        satisfaction: "Satisfaction",
        booking: "Booking",
        contact: "Contact",
      },
      home: {
        title:
          "PRIVATE VAN TRANSPORT SERVICE: YOUR RELIABLE PARTNER FOR HASSLE-FREE JOURNEYS.",
        serviceDescription:
          "Travel with complete peace of mind with our transportation service. Whether you're heading for the airport, train station or another destination, we'll ensure your comfort and safety. Book easily in just a few clicks and benefit from experienced drivers for your business or personal travel. Book now for a pleasant travel experience.",
        paymentMethods: "Payment Methods",
        teamPro: "Expert Drivers",
        travelExcellence: "Travel with excellence!",
        comfortVTC: "Luxurious Transport",
        travelStyle: "Travel with style",
        onlinePrice: "Price Information",
        checkNow: "Check now!",
        onlineOrder: "Immediate Booking",
        simpleFastOrder: "Quick and easy booking",
        ourRates: "Our Rates",
        discoverOur: "Discover Our",
        rates: "Rates",
        learnMore: "Learn More",
        ourServices: "Our Services",
        services: "Services",
      },
      car: {
        title: "Welcome Aboard Your VTC 77 Seine-et-Marne",
        welcomeMessage:
          "Welcome aboard your VTC, where every journey is a luxury experience. Our Mercedes Vito Salon Mode van welcomes you with comfort and elegance, offering space for 1 to 7 passengers. Connect with our onboard WiFi, charge your devices with our 24-volt sockets, and never run out of battery with our iOS and Android chargers. Relax with our candies, treats, and bottled water. Explore your destination with our tourist brochures, while baby seats and boosters ensure the safety of your youngest passengers. And of course, your driver is at your service, speaking both French and English to make your journey even more enjoyable.",
        mercedesVanDescription: "Mercedes Vito Van: 1 to 7 passengers",
      },
      teams: {
        title: "Highly Qualified VTC Team",
        description:
          "Our team of professionals has established itself as a trusted and efficient reference in the field of private transport. Our commitment to service excellence has allowed us to build a solid reputation. Each member of our team is rigorously trained and shares a common passion for the profession of chauffeur. Our expertise allows us to ensure an impeccable travel experience, regardless of the starting point or intended destination. With a spacious 7-seater van, we adapt to all your travel needs, whether for an airport transfer or a group outing.",
        accordionTitle: "Exceptional Commitment",
        accordionSubtitle: "Your Guarantee for a Successful Trip",
        accordionContent:
          "Our private transport service embodies professionalism and efficiency in the field of personal travel. Our absolute priority is your satisfaction, ensured from the start by our commitment to punctuality. No matter your departure location, our team guarantees prompt pickup and an optimal route to your destination. With our extensive expertise, you can travel with confidence, knowing that your safety and comfort are our top priorities. Our experienced chauffeurs ensure that every trip is pleasant and hassle-free, paying close attention to every detail. In addition to our commitment to punctuality and safety, we strive to offer you a personalized service tailored to your needs. Whether you are traveling for business or pleasure, we are here to make your trip as comfortable as possible. Our fleet of high-end vehicles is regularly maintained to ensure your satisfaction with every trip. By choosing our private transport service, you prioritize reliability, comfort, and peace of mind. We make every effort to guarantee you an exceptional travel experience, whether for an airport transfer, a tourist excursion, or any other destination. Every trip with us is an opportunity to provide you with impeccable service and a memorable experience. Trust our team to accompany you with professionalism and dedication at every stage of your journey.",
      },
      services: {
        title: "VTC Transport Services",
        airport: {
          title: "Airport Transport",
          subtitle: "Stress-Free Travel",
          description:
            "Our airport transport service ensures a serene arrival or departure. Our professional drivers greet you with punctuality and courtesy, guaranteeing a comfortable and stress-free journey.",
          details:
            "Our priority is to make your airport transfers as smooth as possible. We monitor your flight in real-time to adjust the pick-up time in case of delays or early arrivals. With our spacious 7-seater van, you and your luggage will travel comfortably.",
        },
        station: {
          title: "Station Transport",
          subtitle: "Punctuality Guaranteed",
          description:
            "Opt for our station transport service for a quick and efficient pick-up. We ensure a comfortable and punctual journey, whether for departure or arrival.",
          details:
            "Our station transport service is designed to offer you a hassle-free experience. We guarantee a punctual pick-up and optimal journey to or from the station of your choice. Our drivers are well-versed in the schedules and routes to avoid any time loss.",
        },
        park: {
          title: "Amusement Parks",
          subtitle: "Worry-Free Fun",
          description:
            "Discover our transport services to amusement parks. Enjoy a pleasant and relaxed journey with family or friends, without worrying about parking or routes.",
          details:
            "Our transport to amusement parks allows you to start your day of fun without stress. Our drivers know the best routes and drop you off closest to the entrances. Travel in a group, with all the necessary comfort.",
        },
        business: {
          title: "Business Transport",
          subtitle: "Professionalism and Discretion",
          description:
            "Trust our business transport service for your professional trips. We ensure your comfort and punctuality, so you can focus on your business.",
          details:
            "Our business transport service is designed to meet the needs of demanding professionals. Our drivers are punctual, discreet, and know the best routes to avoid traffic. Enjoy our spacious van to work or relax between meetings.",
        },
        wedding: {
          title: "Wedding Chauffeur",
          subtitle: "Elegance and Comfort",
          description:
            "Add a touch of elegance to your special day with our wedding chauffeur service. We guarantee luxurious and worry-free transport, so you can focus on your celebrations.",
          details:
            "Our wedding chauffeur service ensures that every detail of your transport is perfect. Our vehicles are decorated according to your preferences, and our chauffeurs are elegantly dressed to match your wedding theme. We guarantee that you will arrive at your destinations on time and in great comfort.",
        },
        tourist: {
          title: "Tourist Transport",
          subtitle: "Exploration and Comfort",
          description:
            "Explore tourist sites with our tourist transport service. We offer a personalized travel experience with routes tailored to your desires and expert local advice.",
          details:
            "Our tourist transport service allows you to discover the best attractions with comfort and ease. Our drivers are also genuine local guides, ready to recommend the best sites and restaurants. Travel with peace of mind in our spacious van, adapted to your needs.",
        },
        event: {
          title: "Event Transport",
          subtitle: "Punctuality and Safety",
          description:
            "Trust our event transport service for all your major events. Whether it's a concert, conference, or private party, we ensure smooth and punctual trips for you and your guests.",
          details:
            "Our event transport service is ideal for ensuring your guests' transportation during major occasions. We guarantee hassle-free, safe, and punctual trips, so you can fully enjoy your event without logistical worries.",
        },
        allDrive: {
          title: "24/7 Transport Service",
          subtitle: "Available Anytime",
          description:
            "We offer a transport service available at any time of the day or night, for all distances. Whether you need a short or long trip, our team is ready to take you wherever you want.",
          details:
            "Our all-distance, day and night transport service is perfect for those who need flexibility and availability at any time. We are committed to providing you with a safe, comfortable, and fast trip, regardless of the distance or time.",
        },
      },
      price: {
        serviceAndRates: "Service and Rates of VTC",
        vtcTransportService: "VTC Transport Service",
        vtcServiceDescription:
          "To travel in complete tranquility, it is essential to have a reliable transport service. Our VTC company stands out for the exceptional quality of its services and the total transparency of its rates. Our primary goal is to offer you a high-end travel experience, while guaranteeing you competitive rates. Whether you are planning a short local trip or a longer journey, our rates are designed to meet your specific needs. Thanks to our variety of vehicles, you can enjoy optimal comfort without compromising your budget.",
        ratesAndOnlineQuoteRequest: "Rates and Online Quote Request",
        guaranteeOfSuccessfulTrip: "Guarantee of a Successful Trip",
        ratesOnlineQuoteDescription:
          "Knowing the costs is essential when planning your trips. That's why at our VTC service, we provide you with an online tool to quickly and efficiently get an estimate of our rates. In just a few clicks, discover the price of your trip, whether you are planning a transfer to another city or a specific destination. For specific needs or special requests, our team is ready to provide you with a personalized offer. We are committed to offering you transparent and accessible rates, so you can travel with confidence.",
        simplifyYourTransportReservations:
          "Simplify Your Transport Reservations",
        reservationProcessDescription:
          "When it comes to travel, we believe in simplicity. We are committed to making the reservation process as simple and efficient as possible for you. Whether you are planning a trip between different destinations, a family outing, or a getaway with friends, we are committed to making every step of the reservation process transparent and hassle-free. We provide you with clear information, flexible options, and attentive customer service to answer all your questions and ensure a hassle-free booking experience.",
        bookYourTransportOnline: "Book Your Transport Online with Ease",
        guaranteeOfStressFreeTrip: "Guarantee of a Stress-Free Trip",
        onlineBookingDescription:
          "In this era of increasing connectivity, we offer you the possibility to book your transport online in a simple and secure manner. In just a few clicks, you can choose your route, select the service that best meets your needs, and finalize your reservation. Whether it is for immediate transport or planning ahead for a special event, our online platform guarantees you a smooth and stress-free booking experience. Our team is available to answer all your questions at every step of the process. Booking your transport has never been so convenient!",
      },
      satisfied: {
        satisfiedTitle: "Personalized Luxury Transport",
        satisfiedDescription:
          "Choosing the right transport service can greatly influence your travel experience. Our private transport company stands out for its commitment to luxury and personalization. We understand that each client is unique, which is why we offer tailor-made services to meet your specific needs. Whether you are looking for a quick transfer to your destination or a longer journey for a special occasion, we are committed to making each trip as comfortable and enjoyable as possible. \n\nOur team of professional chauffeurs is trained to provide top-quality service, combining courtesy and discretion. We welcome you with a warm smile and ensure that every detail of your trip is taken care of. Whether you are traveling for business or pleasure, we guarantee a worry-free transport experience where your comfort and satisfaction are our absolute priorities.\n\nBook now to experience the luxury and comfort of personalized transport. Let us transform your journey into an unforgettable experience, where every moment is carefully planned and executed to meet your needs and exceed your expectations.",
        accordionTitle: "Book for an Experience",
        accordionSubtitle: "Exceptional Travel",
        accordionContent:
          "Leave nothing to chance when planning your next trip. Anticipate an unparalleled transport experience with our luxury service. Immerse yourself in a world of absolute comfort where every aspect of your journey is meticulously thought out to offer you an incomparable experience. Relax and enjoy peace of mind knowing that your safety is our top priority. With our highly personalized approach, every trip becomes a work of art, carefully tailored to your unique needs and preferences. Book now and let us take you to your destination with elegance and distinction, creating unforgettable memories at every mile.",
      },
      reservation: {
        title: "Pricing Options for Your VTC Trips",
        departureAddress: "Departure Address",
        vehicle: "Vehicle",
        van: "Van",
        destinationAddress: "Destination Address",
        passengers: "Passengers",
        departureDateTime: "Departure Date and Time",
        returnDateTime: "Return Date and Time",
        luggage: "Luggage",
        oneWay: "One Way",
        roundTrip: "Round Trip",
        estimatePrice: "Estimate Price",
        price: "Price : {{price}} € (may vary)",
        contactUs: "Contact us.",
        book: "Book",
        vehicleRequired: "Please select a vehicle",
        dateTimeRequired: "Please select a departure date and time",
        dateTimeReturnRequired: "Please select a return date and time",
        departureAddressRequired: "Please select a valid departure address",
        destinationAddressRequired: "Please select a valid destination address",
      },
      contact: {
        title: "Contact Us",
        description: "You can contact us via WhatsApp or phone:",
        formTitle: "Contact and Custom Quote Request",
        fields: {
          firstName: {
            label: "First Name",
            error: "First name is required",
          },
          lastName: {
            label: "Last Name",
            error: "Last name is required",
          },
          email: {
            label: "Email",
            errorRequired: "Email is required",
            errorInvalid: "Email is not valid",
          },
          phone: {
            label: "Phone",
            errorRequired: "Phone is required",
            errorInvalid: "Phone is not valid",
          },
          message: {
            label: "Your Request",
            error: "Message is required",
          },
        },
        buttons: {
          submit: "Submit",
        },
      },
      footer: {
        copyright: "© {{year}} Bendrive.",
        legalNotice: "Legal notice",
        googleReviews: "Google Reviews",
      },
      legalNotice: {
        title: "Legal Notice",
        companyInfo: "Company Information",
        companyDetails:
          "By choosing Bendrive, you can be assured of dealing with a serious and reliable company. Here are some details about our company:",
        companyName: "Company Name: Bendrive",
        address: "Address: 31 AV DE SEGUR 75007 PARIS",
        phone: "Phone: +33 649151200",
        email: "Email: jeanbernardvtc720@gmail.com",
        siret: "SIRET: 83336474800024",
        tva: "VAT: FR57833364748",
        transparency:
          "At Bendrive, transparency and integrity are at the heart of our values. We offer an exceptional customer service experience and are committed to providing you with top-quality services at all times.",
        host: "Host",
        hostDetails:
          "To ensure the reliability and security of our online services, we work with a trusted host. Here are the details of our host:",
        hostName: "Google LLC",
        hostAddress: "1600 Amphitheatre Parkway Mountain View, CA 94043 USA",
        hostPhone: "+1 650-253-0000",
        website: "cloud.google.com",
        dataPolicy: "Data Policy",
        dataPolicyDetails:
          "At Bendrive, we respect your privacy and take the protection of your personal data very seriously. We do not store any personal data, except for contacts you may establish with our drivers by email. In this case, your information is secured and used only for responding to your requests and providing you with personalized customer service.",
        copyRestrictions: "Copy Restrictions",
        copyRestrictionsDetails:
          "Intellectual property is a value we defend firmly. Any attempt to copy or reproduce our website, in whole or in part, constitutes a violation of our copyright and is strictly prohibited. Article L.335-2.",
        photoCredit: "Photo Credit",
        photoCreditDetails:
          "The images used on our website are carefully selected from reputable sources and comply with copyright standards. We would like to thank Freepik for their contribution in providing high-quality graphic resources.",
      },
      notifications: {
        emailSuccess: "Email sent successfully!",
        emailError: "Failed to send email. Please try again later.",
      },
    },
  },

  fr: {
    translation: {
      sidebar: {
        home: "Accueil",
        vehicles: "Véhicules",
        drivers: "Équipes",
        services: "Services",
        pricing: "Prestations & Tarifs",
        satisfaction: "Satisfaction",
        booking: "Réservation",
        contact: "Contact",
      },
      home: {
        title:
          "SERVICE DE TRANSPORT PRIVÉ EN VAN 7 PLACES : VOTRE PARTENAIRE FIABLE POUR DES TRAJETS SANS TRACAS.",
        serviceDescription:
          "Voyagez en toute tranquillité avec notre service de transport. Que ce soit pour l'aéroport, la gare, ou une autre destination, nous vous assurons confort et sécurité. Réservez facilement en quelques clics et profitez de chauffeurs expérimentés pour vos déplacements professionnels ou personnels. Réservez maintenant pour une expérience de voyage agréable.",
        paymentMethods: "Moyens de paiement",
        teamPro: "Chauffeurs experts",
        travelExcellence: "Voyagez avec excellence!",
        comfortVTC: "Transport luxueux",
        travelStyle: "Voyagez avec style",
        onlinePrice: "Infos de Tarification",
        checkNow: "Consultez maintenant!",
        onlineOrder: "Réservation immédiate",
        simpleFastOrder: "Réservation simple rapide",
        ourRates: "Nos Tarifs",
        discoverOur: "Découvrir Nos",
        rates: "Tarifs",
        learnMore: "En Savoir Plus",
        ourServices: "Nos Services",
        services: "Services",
      },
      car: {
        title: "Bienvenue À Bord De Votre VTC 77 Seine-Et-Marne",
        welcomeMessage:
          "Bienvenue à bord de votre VTC, où chaque voyage est une expérience de luxe. Notre van Mercedes Vito Mode Salon vous accueille avec confort et élégance, offrant un espace pour 1 à 7 passagers. Connectez-vous avec notre WiFi à bord, rechargez vos appareils avec nos prises 24 volts et ne manquez jamais de batterie avec nos chargeurs pour iOS et Android. Détendez-vous avec nos confiseries, friandises et bouteilles d'eau à disposition. Explorez votre destination avec nos brochures touristiques, tandis que les sièges bébé et rehausseurs assurent la sécurité de vos plus jeunes passagers. Et bien sûr, votre chauffeur est à votre service, parlant français et anglais pour rendre votre voyage encore plus agréable.",
        mercedesVanDescription: "Van Mercedes Vito : 1 à 7 passagers",
      },
      teams: {
        title: "Équipe VTC Hautement Qualifiée",
        description:
          "Notre équipe de professionnels s'est établie comme une référence de confiance et d'efficacité dans le domaine du transport privé. Notre engagement envers un service d'excellence nous a permis de bâtir une solide réputation. Chaque membre de notre équipe est rigoureusement formé et partage une passion commune pour le métier de chauffeur. Notre expertise nous permet d'assurer une expérience de voyage impeccable, quel que soit le point de départ ou la destination envisagée. Avec un van spacieux de 7 places, nous nous adaptons à tous vos besoins de déplacement, que ce soit pour un transfert aéroportuaire ou une sortie en groupe.",
        accordionTitle: "L'engagement Exceptionnel",
        accordionSubtitle: "Votre Garantie Pour Un Voyage Réussi",
        accordionContent:
          "Notre service de transport privé incarne le professionnalisme et l'efficacité dans le domaine du déplacement personnel. Notre priorité absolue est votre satisfaction, dès le départ assurée par notre engagement envers la ponctualité. Peu importe votre lieu de départ, notre équipe garantit une prise en charge rapide et un itinéraire optimal pour vous mener à destination. Grâce à notre expertise étendue, vous pouvez voyager en toute confiance, sachant que votre sécurité et votre confort sont nos priorités absolues. Nos chauffeurs expérimentés veillent à ce que chaque trajet soit agréable et sans encombre, en accordant une attention particulière à chaque détail. En plus de notre engagement envers la ponctualité et la sécurité, nous nous efforçons de vous offrir un service personnalisé adapté à vos besoins. Que vous voyagiez pour des raisons professionnelles ou pour le plaisir, nous sommes là pour rendre votre trajet aussi confortable que possible. Notre flotte de véhicules haut de gamme est régulièrement entretenue pour garantir votre satisfaction à chaque déplacement. En faisant le choix de notre service de transport privé, vous privilégiez la fiabilité, le confort et la tranquillité d'esprit. Nous déployons tous nos efforts pour vous garantir une expérience de voyage exceptionnelle, que ce soit pour un transfert vers l'aéroport, une excursion touristique ou toute autre destination. Chaque déplacement avec nous est une occasion de vous offrir un service irréprochable et une expérience mémorable. Faites confiance à notre équipe pour vous accompagner avec professionnalisme et dévouement à chaque étape de votre voyage.",
      },
      services: {
        title: "Services de Transport VTC",
        airport: {
          title: "Transport Aéroport",
          subtitle: "Voyage Sans Stress",
          description:
            "Notre service de transport aéroportuaire assure une arrivée ou un départ serein. Nos chauffeurs professionnels vous accueillent avec ponctualité et courtoisie, garantissant un trajet confortable et sans stress.",
          details:
            "Notre priorité est de rendre vos transferts aéroportuaires aussi fluides que possible. Nous surveillons votre vol en temps réel pour ajuster l'heure de prise en charge en cas de retard ou d'arrivée anticipée. Avec notre van spacieux de 7 places, vous et vos bagages voyagerez confortablement.",
        },
        station: {
          title: "Transport Gare",
          subtitle: "Ponctualité Garantie",
          description:
            "Optez pour notre service de transport gare pour une prise en charge rapide et efficace. Nous assurons un trajet confortable et ponctuel, que ce soit pour un départ ou une arrivée.",
          details:
            "Notre service de transport gare est conçu pour vous offrir une expérience sans souci. Nous garantissons une prise en charge ponctuelle et un trajet optimal vers ou depuis la gare de votre choix. Nos chauffeurs connaissent bien les horaires et les itinéraires pour éviter toute perte de temps.",
        },
        park: {
          title: "Parcs d'Attractions",
          subtitle: "Amusement Sans Souci",
          description:
            "Découvrez nos services de transport vers les parcs d'attractions. Profitez d'un trajet agréable et détendu en famille ou entre amis, sans vous soucier du stationnement ou des itinéraires.",
          details:
            "Notre transport vers les parcs d'attractions vous permet de commencer votre journée de divertissement sans stress. Nos chauffeurs connaissent les meilleurs itinéraires et vous déposent au plus près des entrées. Voyagez en groupe, avec tout le confort nécessaire.",
        },
        business: {
          title: "Transport Professionnel",
          subtitle: "Professionnalisme et Discrétion",
          description:
            "Faites confiance à notre service de transport professionnel pour vos déplacements d'affaires. Nous assurons votre confort et votre ponctualité, afin que vous puissiez vous concentrer sur vos affaires.",
          details:
            "Notre service de transport professionnel est conçu pour répondre aux besoins des professionnels exigeants. Nos chauffeurs sont ponctuels, discrets et connaissent les meilleurs itinéraires pour éviter le trafic. Profitez de notre van spacieux pour travailler ou vous détendre entre les réunions.",
        },
        wedding: {
          title: "Chauffeur de Mariage",
          subtitle: "Élégance et Confort",
          description:
            "Ajoutez une touche d'élégance à votre journée spéciale avec notre service de chauffeur de mariage. Nous garantissons un transport luxueux et sans souci, pour que vous puissiez vous concentrer sur vos célébrations.",
          details:
            "Notre service de chauffeur de mariage veille à ce que chaque détail de votre transport soit parfait. Nos véhicules sont décorés selon vos préférences, et nos chauffeurs sont élégamment vêtus pour s'accorder à votre thème de mariage. Nous garantissons que vous arriverez à vos destinations à temps et avec un grand confort.",
        },
        tourist: {
          title: "Transport Touristique",
          subtitle: "Exploration et Confort",
          description:
            "Explorez les sites touristiques avec notre service de transport touristique. Nous offrons une expérience de voyage personnalisée avec des itinéraires adaptés à vos envies et des conseils locaux avisés.",
          details:
            "Notre service de transport touristique vous permet de découvrir les meilleures attractions avec confort et facilité. Nos chauffeurs sont aussi de véritables guides locaux, prêts à recommander les meilleurs sites et restaurants. Voyagez en toute tranquillité dans notre van spacieux, adapté à vos besoins.",
        },
        event: {
          title: "Transport Événementiel",
          subtitle: "Ponctualité et Sécurité",
          description:
            "Faites confiance à notre service de transport événementiel pour tous vos grands événements. Que ce soit un concert, une conférence ou une fête privée, nous assurons des trajets fluides et ponctuels pour vous et vos invités.",
          details:
            "Notre service de transport événementiel est idéal pour garantir le transport de vos invités lors des grandes occasions. Nous garantissons des trajets sans souci, sûrs et ponctuels, pour que vous puissiez profiter pleinement de votre événement sans tracas logistiques.",
        },
        allDrive: {
          title: "Service de Transport 24/7",
          subtitle: "Disponible à Tout Moment",
          description:
            "Nous offrons un service de transport disponible à tout moment de la journée ou de la nuit, pour toutes les distances. Que vous ayez besoin d'un trajet court ou long, notre équipe est prête à vous emmener où vous voulez.",
          details:
            "Notre service de transport toutes distances, jour et nuit, est parfait pour ceux qui ont besoin de flexibilité et de disponibilité à tout moment. Nous nous engageons à vous fournir un trajet sûr, confortable et rapide, quelle que soit la distance ou l'heure.",
        },
      },
      price: {
        serviceAndRates: "Prestations est Tarifs de VTC",
        vtcTransportService: "Service de Transport VTC",
        vtcServiceDescription:
          "Pour se déplacer en toute tranquillité, il est essentiel de bénéficier d'un service de transport fiable. Notre entreprise de VTC se distingue par la qualité exceptionnelle de ses prestations et la totale transparence de ses tarifs. Notre objectif premier est de vous offrir une expérience de voyage haut de gamme, tout en vous garantissant des tarifs compétitifs. Que vous envisagiez un court déplacement local ou un trajet plus long, nos tarifs sont conçus pour répondre à vos besoins spécifiques. Grâce à notre variété de véhicules, vous pourrez profiter d'un confort optimal sans compromettre votre budget.",
        ratesAndOnlineQuoteRequest: "Tarifs et Demande de Devis en Ligne",
        guaranteeOfSuccessfulTrip: "Garantie d'un Voyage Réussi",
        ratesOnlineQuoteDescription:
          "La connaissance des coûts est essentielle lors de la planification de vos déplacements. C'est pourquoi chez notre service de VTC, nous mettons à votre disposition un outil en ligne pour obtenir rapidement et efficacement une estimation de nos tarifs. En quelques clics, découvrez le prix de votre trajet, que vous envisagiez un transfert vers une autre ville ou une destination spécifique. Pour des besoins spécifiques ou des demandes particulières, notre équipe est prête à vous fournir une offre personnalisée. Nous nous engageons à vous offrir des tarifs transparents et accessibles, pour que vous puissiez voyager en toute confiance.",
        simplifyYourTransportReservations:
          "Simplifiez Vos Réservations de Transport",
        reservationProcessDescription:
          "Quand il s'agit de voyager, nous croyons en la simplicité. Nous nous engageons à rendre le processus de réservation aussi simple et efficace que possible pour vous. Que vous prévoyiez un voyage entre différentes destinations, une sortie en famille ou une escapade entre amis, nous nous engageons à rendre chaque étape de la réservation transparente et sans soucis. Nous mettons à votre disposition des informations claires, des options flexibles et un service client attentif pour répondre à toutes vos questions et assurer une expérience de réservation sans tracas.",
        bookYourTransportOnline:
          "Réservez Votre Transport en Ligne en Toute Simplicité",
        guaranteeOfStressFreeTrip: "Garantie d'un Voyage Sans Souci",
        onlineBookingDescription:
          "Dans cette ère de connectivité croissante, nous vous offrons la possibilité de réserver votre transport en ligne de manière simple et sécurisée. En quelques clics, vous pouvez choisir votre itinéraire, sélectionner le service qui répond le mieux à vos besoins et finaliser votre réservation. Que ce soit pour un transport immédiat ou une planification anticipée pour un événement spécial, notre plateforme en ligne vous garantit une expérience de réservation fluide et sans stress. Notre équipe est disponible pour répondre à toutes vos questions à chaque étape du processus. Réserver votre transport n'a jamais été aussi pratique!",
      },
      satisfied: {
        satisfiedTitle: "Transport de Luxe Personnalisé",
        satisfiedDescription:
          "Le choix du service de transport peut grandement influencer votre expérience de voyage. Notre compagnie de transport privé se distingue par son engagement envers le luxe et la personnalisation. Nous comprenons que chaque client est unique, c'est pourquoi nous offrons des services sur mesure pour répondre à vos besoins spécifiques. Que vous recherchiez un transfert rapide vers votre destination ou un voyage plus long pour une occasion spéciale, nous nous engageons à rendre chaque trajet aussi confortable et agréable que possible. \n\nNotre équipe de chauffeurs professionnels est formée pour offrir un service de qualité supérieure, alliant courtoisie et discrétion. Nous vous accueillons avec un sourire chaleureux et nous assurons que chaque détail de votre voyage est pris en charge. Que vous voyagiez pour affaires ou pour le plaisir, nous vous garantissons une expérience de transport sans souci, où votre confort et votre satisfaction sont notre priorité absolue.\n\nRéservez dès maintenant pour découvrir le luxe et le confort d'un transport personnalisé. Laissez-nous transformer votre voyage en une expérience inoubliable, où chaque moment est soigneusement planifié et exécuté pour répondre à vos besoins et dépasser vos attentes.",
        accordionTitle: "Réservez pour une expérience",
        accordionSubtitle: "Voyage exceptionnel",
        accordionContent:
          "Ne laissez rien au hasard lors de la planification de votre prochain voyage. Anticipez dès maintenant une expérience de transport inégalée avec notre service de luxe. Plongez dans un monde de confort absolu où chaque aspect de votre trajet est méticuleusement pensé pour vous offrir une expérience incomparable. Détendez-vous et profitez de la tranquillité d'esprit en sachant que votre sécurité est notre priorité absolue. Avec notre approche hautement personnalisée, chaque déplacement devient une œuvre d'art, soigneusement adaptée à vos besoins et à vos préférences uniques. Réservez dès aujourd'hui et laissez-nous vous emmener vers votre destination avec élégance et distinction, créant ainsi des souvenirs inoubliables à chaque kilomètre parcouru.",
      },
      reservation: {
        title: "Options de Tarification pour vos Trajets en VTC",
        departureAddress: "Adresse de départ",
        vehicle: "Véhicule",
        van: "Van",
        destinationAddress: "Adresse de destination",
        passengers: "Passagers",
        departureDateTime: "Date et heure aller",
        returnDateTime: "Date et heure retour",
        luggage: "Bagages",
        oneWay: "Aller simple",
        roundTrip: "Aller-retour",
        estimatePrice: "Estimation du tarif",
        price: "prix : {{price}} € (peut varier)",
        contactUs: "Contactez-nous.",
        book: "Réserver",
        vehicleRequired: "Veuillez sélectionner un véhicule",
        dateTimeRequired: "Veuillez sélectionner une date et heure de départ",
        dateTimeReturnRequired:
          "Veuillez sélectionner une date et heure de retour",
        departureAddressRequired:
          "Veuillez sélectionner une adresse de départ valide",
        destinationAddressRequired:
          "Veuillez sélectionner une adresse de destination valide",
      },
      contact: {
        title: "Contactez-nous",
        description:
          "Vous pouvez nous contacter par WhatsApp ou par téléphone :",
        formTitle: "Demande de Contact et de Devis Personnalisé",
        fields: {
          firstName: {
            label: "Nom",
            error: "Le prénom est requis",
          },
          lastName: {
            label: "Prénom",
            error: "Le nom est requis",
          },
          email: {
            label: "E-Mail",
            errorRequired: "L'email est requis",
            errorInvalid: "L'email n'est pas valide",
          },
          phone: {
            label: "Téléphone",
            errorRequired: "Le téléphone est requis",
            errorInvalid: "Le téléphone n'est pas valide",
          },
          message: {
            label: "Votre Demande",
            error: "La demande est requise",
          },
        },
        buttons: {
          submit: "Envoyer",
        },
      },
      footer: {
        copyright: "© {{year}} Bendrive.",
        legalNotice: "Mentions légales",
        googleReviews: "Avis Google",
      },
      legalNotice: {
        title: "Mentions Légales",
        companyInfo: "Informations sur la société",
        companyDetails:
          "En choisissant Bendrive, vous bénéficiez de la tranquillité d'esprit en sachant que vous faites affaire avec une entreprise sérieuse et fiable. Voici quelques détails sur notre société :",
        companyName: "Nom de la société : Bendrive",
        address: "Adresse : 31 AV DE SEGUR 75007 PARIS",
        phone: "Téléphone : +33 649151200",
        email: "Email: jeanbernardvtc720@gmail.com",
        siret: "SIRET: 83336474800024",
        tva: "VAT: FR57833364748",
        transparency:
          "Chez Bendrive, la transparence et l'intégrité sont au cœur de nos valeurs. Nous vous offrons une expérience de service client exceptionnelle et nous nous engageons à vous fournir des services de qualité supérieure à tout moment.",
        host: "Hébergeur",
        hostDetails:
          "Pour assurer la fiabilité et la sécurité de nos services en ligne, nous travaillons avec un hébergeur de confiance. Voici les détails de notre hébergeur :",
        hostName: "Google LLC",
        hostAddress: "1600 Amphitheatre Parkway Mountain View, CA 94043 USA",
        hostPhone: "+1 650-253-0000",
        website: "cloud.google.com",
        dataPolicy: "Politique de données",
        dataPolicyDetails:
          "Chez Bendrive, nous respectons votre vie privée et nous prenons la protection de vos données personnelles très au sérieux. Nous ne stockons aucune donnée personnelle, à l'exception des contacts que vous pouvez établir avec nos chauffeurs par email. Dans ce cas, vos informations sont sécurisées et utilisées uniquement dans le but de répondre à vos demandes et de vous fournir un service client personnalisé.",
        copyRestrictions: "Restrictions de copie",
        copyRestrictionsDetails:
          "La propriété intellectuelle est une valeur que nous défendons avec fermeté. Toute tentative de copier ou de reproduire notre site web, en tout ou en partie, constitue une violation de nos droits d'auteur et est strictement interdite. Article L.335-2.",
        photoCredit: "Crédit photos",
        photoCreditDetails:
          "Les images utilisées sur notre site web sont soigneusement sélectionnées auprès de sources réputées et respectent les normes de droit d'auteur. Nous tenons à remercier Freepik pour leur contribution en fournissant des ressources graphiques de haute qualité.",
      },
      notifications: {
        emailSuccess: "Email envoyé avec succès !",
        emailError:
          "Échec de l'envoi de l'email. Veuillez réessayer plus tard.",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
