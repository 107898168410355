import React from "react";

const Home = React.lazy(() => import("./components/pages/home/Home"));
const Car = React.lazy(() => import("./components/pages/car/Car"));
const Teams = React.lazy(() => import("./components/pages/teams/Teams"));
const Price = React.lazy(() => import("./components/pages/price/Price"));
const Services = React.lazy(() =>
  import("./components/pages/services/Services")
);
// const Satisfied = React.lazy(() =>
//   import("./components/pages/satisfied/Satisfied")
// );
const Reservation = React.lazy(() =>
  import("./components/pages/reservation/Reservation")
);
const Contact = React.lazy(() => import("./components/pages/contact/Contact"));

const LegalNotice = React.lazy(() =>
  import("./components/DefaultLayout/footer/LegalNotice")
);
const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    roules: ["admin", "manager", "user"],
  },
  {
    path: "/home",
    exact: true,
    index: true,
    name: "Home",
    element: Home,
    roules: ["admin", "manager", "user"],
  },
  {
    path: "/car",
    exact: true,
    index: true,
    name: "Car",
    element: Car,
    roules: ["admin", "manager", "user"],
  },
  {
    path: "/teams",
    exact: true,
    index: true,
    name: "Teams",
    element: Teams,
    roules: ["admin", "manager", "user"],
  },
  {
    path: "/price",
    exact: true,
    index: true,
    name: "Price",
    element: Price,
    roules: ["admin", "manager", "user"],
  },
  {
    path: "/services",
    exact: true,
    index: true,
    name: "Services",
    element: Services,
    roules: ["admin", "manager", "user"],
  },
  // {
  //   path: "/satisfied",
  //   exact: true,
  //   index: true,
  //   name: "satisfied",
  //   element: Satisfied,
  //   roules: ["admin", "manager", "user"],
  // },
  {
    path: "/reservation",
    exact: true,
    index: true,
    name: "Reservation",
    element: Reservation,
    roules: ["admin", "manager", "user"],
  },
  {
    path: "/contact",
    exact: true,
    index: true,
    name: "Contact",
    element: Contact,
    roules: ["admin", "manager", "user"],
  },
  {
    path: "/legalnotice",
    exact: true,
    index: true,
    name: "legalnotice",
    element: LegalNotice,
    roules: ["admin", "manager", "user"],
  },
];

export default routes;
