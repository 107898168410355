import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import routes from "./routes";
import i18n from "./components/utils/translate/i18n";
import CircularIndeterminate from "./components/utils/progress/CircularProgress";

const DefaultLayout = React.lazy(() =>
  import("./components/DefaultLayout/DefaultLayout")
);

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<DefaultLayout />}>
            <Route exact path="*" element={<Navigate to="/home" replace />} />
            <Route exact path="/" element={<Navigate to="/home" replace />} />
            {routes.map((route) => {
              return route.element ? (
                <Route
                  key={route.name}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  index={route.index || false}
                  element={
                    <Suspense fallback={<CircularIndeterminate />}>
                      <route.element />
                    </Suspense>
                  }></Route>
              ) : null;
            })}
          </Route>
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;
